.account {
  .profile-page__content {
    .profile-info__item.title_container {
      float: none;
      width: 100%;
    }
    .password_help_text {
      float: left;
      margin: 0 5% 20px 20px;
    }
  }
  label.error {
    color: $color-red;
  }
  .title-heading label.error {
    color: $color-black;
  }
  &-page__content {
    .section-header:before {
      background: none;
    }
    .account-profile__newsletter .newsletter_text {
      margin-bottom: 0;
    }
  }
  &__section {
    .sms_promotions {
      margin-bottom: 10px;
      margin-left: 20px;
    }
    .profile-pic-main {
      #profile-pic-name {
        word-wrap: break-word;
      }
    }
  }
  #address_form_container {
    .address-overlay-billing,
    .address-overlay-shipping {
      .form_element {
        margin-bottom: 6px;
      }
      input[type='text'],
      select {
        width: 100%;
        margin-bottom: 10px;
      }
      h2 {
        margin-bottom: 1em;
      }
    }
  }
  .newsletter-info {
    margin-top: 20px;
  }
  .optional-info__item {
    padding: 10px 10px 10px 0px;
    .gender {
      margin-left: 20px;
    }
    span {
      margin-right: 5px;
    }
  }
  .past-purchases-data {
    &-header__item.created-date,
    &-item__item.created-date,
    &-details__item.created-date {
      width: 16%;
    }
    &-header__item.trans-id,
    &-item__item.trans-id,
    &-item-details__item.trans-id {
      width: 23%;
    }
  }
  #cboxWrapper {
    #cboxClose {
      top: 10px;
      right: 5px;
    }
  }
  .sign-in {
    .account-links__welcome {
      word-wrap: break-word;
    }
  }
}

.password-reset {
  &__item {
    .field {
      display: block;
      width: 300px;
    }
  }
}

#order_detail {
  .cart-item {
    &__price {
      width: 21%;
    }
    &__thumb {
      width: 16%;
      padding-right: 0;
    }
    &__qty {
      width: 12%;
      text-align: left;
    }
    &__total {
      width: 16%;
      text-align: right;
      float: left;
    }
    &__desc {
      width: 29%;
      float: left;
    }
  }
  .order-totals {
    width: 40%;
    float: right;
    th,
    tr,
    td {
      border: 0;
    }
    td {
      text-align: right;
    }
  }
  .cart-item__qty-label {
    display: none;
  }
  .order-details-page__content {
    .order-totals {
      th {
        padding-top: 8px;
        padding-bottom: 0px;
        vertical-align: top;
      }
      td {
        padding-top: 8px;
        vertical-align: top;
      }
    }
  }
}

.past-purchases-data {
  &-header__item,
  &-item__item {
    &.created-date {
      width: 16%;
    }
    &.trans-id {
      width: 22%;
    }
    &.count {
      width: 13%;
    }
    &.price {
      width: 16%;
    }
    &.order-status {
      width: 21%;
    }
    &.view-details {
      width: 10%;
    }
  }
}

.past-purchases-page__content {
  width: auto;
  overflow: hidden;
  padding-bottom: 1px;
  .product-item {
    border-top: 1px solid $color-gray-border;
    &__name a {
      font-family: $brandon-text-bold;
      font-size: 11px;
      letter-spacing: 0.22em;
      text-transform: uppercase;
      text-decoration: none;
      color: $color-black;
    }
    &__details {
      margin-bottom: 20px;
    }
    &__add-to-cart .product-item__link {
      margin: 0 0 15px 0;
      background-color: $color-pink;
    }
  }
  .product-item:first-child,
  .product-info {
    border-top: none;
  }
}

.favorites-page__content {
  .favorites__list {
    .product-info-wrapper {
      height: 115px;
    }
  }
}

.sent-info__text {
  margin: 0 0 10px 20px;
}
