.section-store-locator {
  .store-locator__links-container {
    .store-locator__dutyfree {
      display: none;
    }
  }
  #map-canvas {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan right'],
      div[title^='Pan up'],
      div[title^='Pan down'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
  .store-locator--desktop {
    span.icon.icon-finder {
      position: absolute;
      left: 123px;
    }
    span.text {
      position: absolute;
      left: 155px;
    }
    span.store-locator-either-or {
      position: absolute;
      left: 392px;
    }
    span.store-locator-either-or2 {
      position: absolute;
      left: 758px;
      margin-top: 6px;
    }
    input.search {
      position: absolute;
      left: 790px;
    }
    .store-locator__hero select.state {
      height: 32px;
      width: 12%;
      position: absolute;
      left: 430px;
      background-color: #fff;
    }
    .store-locator__hero select.city {
      height: 32px;
      width: 12%;
      position: absolute;
      left: 596px;
      background-color: #fff;
    }
    button.search-submit {
      position: absolute;
      margin-top: 0px;
      left: 1006px;
    }
  }
}

.section-esearch {
  .search-form--results {
    input.search-form__submit {
      margin-#{$ldirection}: 0;
    }
    .search-form__results-count {
      line-height: 2.5;
      #{$rdirection}: 181px;
    }
  }
  #search-wrapper {
    #results-container {
      #col-1 {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
      }
      #col-2 {
        float: none;
        width: 100%;
        #product-results {
          .product-grid__content {
            max-width: 1440px;
            display: flex;
            flex-wrap: wrap;
            .product-grid__item {
              width: calc(100% / 3);
            }
          }
        }
      }
    }
  }
}

#colorbox {
  .waitlist-iframe-wrapper {
    width: 100%;
    height: 95%;
  }
}

.tiny-waitlist-overlay h2 {
  margin: 30px 0 30px 0;
  text-align: left;
}

.tiny-waitlist-overlay .field {
  border-top: 1px solid gray;
  padding-top: 40px;
}

.tiny-waitlist-overlay .email_input {
  display: inline-block;
}

.tiny-waitlist-overlay .email_input input[type='text'] {
  width: 250px;
  margin-bottom: 10px;
}

.tiny-waitlist-overlay .field .align-r {
  display: inline-block;
  margin-left: 20px;
}

.waitlist_thankyou_message {
  font-family: 'Brandon Text Bold';
  margin: 130px 60px 90px 115px;
  line-height: 1.45em;
}

.site-email-sms-signup {
  .site-email-sms-signup__form {
    .site-email-sms-signup__birthday {
      display: none;
    }
  }
}

.ie8 {
  .makeup-lessons-item__thumb-image {
    max-width: none;
  }
}

.site-header {
  .site-utils__links {
    .site-utils__item--live-chat.site-utils__item--icon {
      .site-utils__link-text {
        display: block;
      }
      .site-utils__icon {
        display: none;
      }
    }
  }
}

#header_chat {
  a {
    img {
      vertical-align: top;
      margin-top: 3px;
    }
  }
}

#customer_service_chat {
  a {
    display: inline-block;
    padding-left: 60px;
    padding-top: 9px;
  }
  span {
    top: 0;
    left: 0;
  }
}

#lpChat {
  .lp_actions_bar_container {
    .lp_actions_bar {
      .lp_action_item {
        .lp_action_wrapper {
          .lp_title {
            text-transform: none;
          }
        }
      }
    }
  }
}

#footer_sticky_chat {
  min-height: 65px;
}

.customer-service {
  .customer-service-quick-info {
    .customer-service-quick-info__list-item {
      margin-left: 0%;
    }
  }
}

.lp_radio_button {
  input {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .customer-service-contact-us {
    ul.customer-service-contact-form__categories {
      li {
        letter-spacing: 2px;
        padding: 1em 4px;
      }
    }
  }
  .customer-service-menu {
    li.menu__item--child-count-4,
    li.menu__item--child-count-2 {
      a {
        width: 150px;
        line-height: 16px;
        height: 2.3em;
      }
    }
  }
}

.site-footer__column {
  width: 28%;
}

.trustmark-logo {
  width: 100px;
}

.site-footer {
  .site-footer {
    &__column {
      padding: 0 15px;
    }
    &__column:last-child {
      width: 16%;
      padding: 0 0 0 20px;
    }
  }
}

.offer-code-panel {
  .offer_code_form_container {
    em#one-offer-only {
      span {
        a {
          margin-top: 0px;
        }
      }
    }
  }
}

.node-38824 {
  .product-collection-footer {
    div.align-center:first-child {
      display: none !important;
    }
  }
}

.node-collection {
  .product_collection_hero__content-item {
    #node-44100.node-elc-nodeblock {
      display: none;
    }
  }
}

.node-collection {
  .product_collection_hero__content-item {
    #node-44102 {
      .text-block.text-block_:nth-child(2) {
        display: none;
      }
    }
  }
}

.node-collection {
  .product_collection_hero__content-item {
    #node-44102 {
      .text-block.text-block_:nth-child(1) {
        margin: 0 auto;
      }
    }
  }
}
