.checkout {
  &__panel-title {
    margin-bottom: 1.5em;
  }
  .left.checkout__content {
    .cart-item__price {
      width: 19%;
    }
    .cart-item__total {
      width: 15%;
    }
    .total_column.sub__total {
      margin: 0.8em 0 0.8em 0;
      float: right;
    }
  }
  #offer-code-panel {
    #one-offer-only {
      font-style: normal;
      margin: 18px 0 14px 0;
      display: block;
    }
  }
  ol.checkout-progress li {
    float: left;
    margin-left: 1.5em;
    width: 12.5em;
    list-style-type: decimal;
    margin-top: 1.5em;
  }
  .checkout-header {
    margin: 1.5em 0;
  }
  .checkout-buttons-container {
    margin-bottom: 4em;
    .btn {
      float: right;
    }
  }
  .transaction-panel-details {
    margin-top: 2em;
    .trans_detail_item {
      float: left;
      margin-bottom: 2em;
      margin-right: 2em;
      position: relative;
      width: 16.4em;
      word-wrap: break-word;
      .change_link {
        position: absolute;
        top: 0.6em;
        right: 0;
      }
    }
    .transaction-item.trans_detail_item.gift_container {
      clear: both;
    }
  }
  address {
    font-style: normal;
  }
  #shipping-page-info-panel {
    .shipping-address-info,
    .billing-info {
      .address-container {
        float: left;
        margin: 0 3em 0.8em 1.5em;
        width: 15em;
      }
      .address_controls {
        float: left;
      }
      .address_controls #choose-address {
        margin-top: 0.8em;
        width: 24em;
      }
    }
    .checkout-buttons-container {
      margin-bottom: 0;
    }
  }
  .giftwrap-info {
    margin-bottom: 1.5em;
    #gift-options h2.shipping-panel__title {
      line-height: 18px;
      text-indent: 0;
      margin: 4px 0 10px 0;
      padding-bottom: 17px;
      border-bottom: 1px solid $color-black;
      background-color: $color-white;
    }
  }
  #emailpromo-info-panel {
    float: left;
    margin-top: 20px;
  }
  .sms_promotions {
    margin-bottom: 10px;
  }
  label.error {
    color: $color-red;
  }
}

.shipping .checkout-progress__shipping,
.review .checkout-progress__review,
.billing .checkout-progress__billing,
.confirm .checkout-progress__confirm {
  font-weight: bold;
}

#shipping {
  #billing_address_form_container {
    padding: 20px;
    margin-top: 1.5em;
    border: 1px solid $color-black;
  }
  .address-overlay-shipping,
  .address-overlay-billing {
    .form_element {
      margin-bottom: 6px;
    }
    input[type='text'],
    select {
      width: 100%;
      margin-bottom: 10px;
    }
    h2 {
      margin-bottom: 1em;
    }
  }
}

.margin_top_bottom {
  margin: 0.8em 0;
}

.address-form__state-container {
  float: left;
  width: 50%;
  padding: 4px 10px 10px 0px;
}

.postal_code_container {
  float: left;
  width: 50%;
  padding: 4px 0px 10px 10px;
}

.title_container,
.phone_label_container {
  margin-bottom: 10px;
}

#confirm {
  #confirmation-email {
    margin-bottom: 0.8em;
  }
  .return-link-container {
    margin-bottom: 2em;
  }
}

.order-confirmation {
  .account-utilities {
    display: none;
  }
  .order-totals {
    th,
    tr,
    td {
      border: 0;
    }
    td {
      text-align: right;
    }
  }
  .cart-item:last-child {
    border-bottom: 1px solid $color-light-gray;
    padding-top: 20px;
  }
  .order-details-page__content .cart-item {
    &__thumb {
      width: 16%;
      padding-right: 0;
      margin-right: 10px;
    }
    &__desc {
      width: 29%;
      float: left;
      margin-left: 10px;
    }
    &__price {
      width: 21%;
    }
    &__qty {
      width: 20%;
      clear: none;
      float: left;
      margin: 0 10px;
    }
    &__total {
      width: 9%;
      float: left;
      text-align: right;
    }
  }
}

#signin {
  .checkout__panel-title {
    margin-bottom: 0px;
  }
}

.product.deactivate {
  .sample-select-button,
  .sample-select-button:hover {
    background: $color-gray-light;
    cursor: default;
  }
}

.right.checkout__sidebar {
  .account-utilities {
    &__section {
      background: $color-gray-background;
      padding: 16px 20px;
    }
    &__header {
      font-family: $brandon-text-bold;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      text-indent: 0;
      margin: 4px 0 10px 0;
      padding-bottom: 17px;
      border-bottom: 1px solid $color-black;
    }
  }
}

#links-panel {
  margin-top: 6px;
  border: 5px solid $color-nude;
  padding: 15px 15px 20px 15px;
  background: $color-white;
  li.link {
    text-decoration: none;
    display: block;
    border: 0;
    line-height: 2;
  }
}

.checkout__sidebar {
  .offer-code-panel {
    .checkout__panel-title {
      margin-bottom: 0px;
    }
  }
}

.viewcart-buttons-panel {
  .continue-buttons {
    a.disabled,
    a.disabled:hover {
      background-color: $color-gray-light;
      cursor: default;
    }
  }
}

.checkout__sidebar {
  section {
    margin-bottom: 10px;
  }
  #links-panel {
    margin-top: 0px;
  }
}

.viewcart-panel {
  .empty-cart {
    margin-left: 20px;
  }
}

.checkout__content {
  margin: 35px 0px;
  .shipping-page-info-panel {
    h2 {
      margin-bottom: 10px;
    }
    .checkout-header {
      margin: 1.5em 0px 0px 0px;
    }
    section {
      margin-bottom: 10px;
      section {
        margin-bottom: 0px;
      }
    }
    .delivery-info {
      p {
        margin-bottom: 15px;
      }
    }
    .emailpromo-info {
      h3 {
        text-indent: 0px;
      }
      p {
        margin: 0px 0px 15px 0px;
      }
      input {
        margin-bottom: 0px;
        width: 50%;
      }
      .newsletter-info__item {
        padding-left: 0px;
        margin: 0px;
      }
      .sms-promotions__item {
        padding-left: 0px;
        margin-left: 0px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .viewcart-buttons-panel {
    .continue-buttons {
      width: 48%;
    }
    a.continue-shopping {
      width: 48%;
    }
  }
}

.samples-page .samples-buttons.bottom {
  display: block;
}

.checkout-co-enabled {
  .pg_wrapper {
    .checkout {
      &__sidebar {
        .offer-code-panel {
          &__title {
            border-bottom: none;
            padding-bottom: 0;
          }
          &__content {
            margin-top: 10px;
          }
        }
      }
      &__content {
        .viewcart-panel {
          &__title {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
