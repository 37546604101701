.change-password {
  &-gnav {
    .cms-password-field {
      &__info {
        margin-#{$ldirection}: 0;
        margin-top: 0;
        &:before {
          top: 26%;
          right: 97%;
        }
      }
    }
  }
}
